<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import Watermark from '@/utils/watermark.js';
export default {
  created() {
    // Watermark.set('我是水印');
  },
};
</script>
