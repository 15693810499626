import Vue from 'vue';
import Element from 'element-ui';
Vue.use(Element);
//导入css
import 'normalize.css';
import './style/element-variables.scss';
import '@/style/admin.scss';
//装载指令
import './directive/install';
//装载utils
import '@/utils/install';
//装载组件
import '@/components/install';

import App from './app.vue';
import store from './store';
import { router } from './router';

import uploader from 'vue-simple-uploader';
Vue.use(uploader);

window.app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
if (process.env.NODE_ENV != 'production') {
  document.body.appendChild(
    new Vue({
      ...require('./debug.vue').default,
    }).$mount().$el,
  );
}
window.Vue = Vue;
