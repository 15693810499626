// import {
//   setAreaRange
// } from "@/utils";
const LOCAL_STORAGE_KEY = 'admin-ui-info'; // localStorage 的 key
let storageUser;
try {
  storageUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  // setAreaRange(storageUser.areaIds);
} catch (err) {
  console.warn(err);
  storageUser = {};
}
const state = {
  adminName: '',
  adminId: '',
  token: '',
  permissionList: [],
  //...
};
void storageUser;
export default {
  namespaced: true,
  state: Object.assign(state, storageUser),
  mutations: {},
  actions: {
    /**
     * 记录用户信息
     * @param {*} userData 用户登陆后服务器返回的内容
     * @returns {Promise} -返回promise 为了兼容异步
     */
    login({ state }, userData) {
      return new Promise((resolve) => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userData));
        Object.assign(state, userData);
        // state.username = userData.username;
        // state.token = userData.token;
        // state.userId:userData.userId
        // state.permissionList = userData.permissionList;
        // setAreaRange(userData.areaIds);
        resolve();
      });
    },
    /**
     * 注销
     * @returns {Promise} -返回promise 为了兼容异步
     */
    logout({ state }) {
      return new Promise((resolve) => {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        state.permissionList = [];
        state.adminName = '';
        state.token = '';
        resolve();
      }).catch((err) => {
        console.warn(err);
      });
    },
  },
  getters: {
    token(state) {
      return state.token;
    },
    isLogin(state) {
      console.log(!!state.token);
      return !!state.token;
    },
    isSuperAdmin(state) {
      return state.adminId == 1;
    },
  },
};
