import basicLayout from '@/layout/basic-layout';
import login from '@/views/login';
// const RouteView = {
//   name: 'RouteView',
//   render: (h) => h('router-view'),
// };
// const KeepView = {
//   name: 'KeepView',
//   render: (h) => h('keep-alive', [h('router-view')]),
// };

/**
 * 所有路由中的path 必须为 fullpath 即相对于根目录的绝对路径
 * meta
 *   icon {string} - 栏目图标的class
 *   name {string} - 栏目名称
 *   hide {boolean} - 是否隐藏 （导航）
 *   keep {boolean} - 是否缓存
 *   hideChildren {boolean} -是否隐藏子栏目
 *   p {array} - 权限白名单
 */
export const rootRoutes = {
  path: '/',
  redirect: '/home',
  component: basicLayout,
  children: [
    {
      path: '/home',
      name: 'home',
      meta: {
        name: '街道管理',
        icon: 'el-icon-s-management',
      },
      component: () => import('@/views/home/home.vue'),
    },
    {
      path: '/system',
      component: () => import('@/views/role/index.vue'),
      // redirect: '/role-manage/index',
      meta: {
        name: '系统管理',
        icon: 'el-icon-s-tools',
      },
    },
  ],
};

const routes = [
  {
    path: '/403',
    name: '403',
    meta: {
      tag: false,
      hide: true,
    },
    component: () => import('@/views/page-403.vue'),
  },
  {
    path: '*',
    meta: {
      tag: false,
      hide: true,
    },
    component: () => import('@/views/page-404.vue'),
  },
  {
    path: '/empty',
    meta: {
      hide: true,
    },
    component: basicLayout,
    children: [
      {
        path: '',
        name: 'empty',
        meta: {
          tag: false,
        },
        component: () => import('@/views/page-empty.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      hide: true,
    },
  },
  rootRoutes,
];

export const routeMap = new Map(); // key<Symbol>:permission<array>
window.routeMap = routeMap;
generateRouteMap(routes);

function generateRouteMap(routes) {
  routes.map((route) => {
    route.meta = route.meta || {};
    route.meta.key = Symbol();
    routeMap.set(route.meta.key, route);
    if (route.children && route.children.length) {
      generateRouteMap(route.children);
    }
  });
}

export default routes;
