<template>
  <div>
    <el-cascader
      style="width: 100%"
      refs="select"
      placeholder="输入区域名称查询"
      :filterable="true"
      :filter-method="filterHandler"
      :options="options"
      :props="prop"
      :value="areaValue"
      v-bind="$attrs"
      v-on="$listeners"
    ></el-cascader>
  </div>
</template>

<script>
/**
 * 使用方式
 * <select-area :clearable="false" v-model="aa"></select-area>
 * <select-area :show-all="true" :clearable="false" v-model="aa"></select-area>
 */
export default {
  name: 'select-area',
  props: {
    showAll: {
      //是否显示全部地区
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number],
    },
    selectAny: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
  },
  created() {
    this.$set(this.$data, 'areaValue', String(this.$props.value));
  },
  data() {
    return {
      areaValue: '',
      prop: {
        emitPath: false,
        checkStrictly: this.$props.selectAny,
        value: 'code',
        label: 'name',
      },
      options: Object.freeze(
        this.$props.showAll ? window.SYS_AREA : window.SYS_AREA_MINI,
      ),
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$set(this.$data, 'areaValue', String(this.$props.value));
      },
      deep: true,
    },
  },
  methods: {
    focus() {
      this.$refs.select.focus();
    },
    filterHandler(node, keywords) {
      return node.data.name.indexOf(keywords) != -1;
    },
  },
};
</script>

<style></style>
