<template>
  <el-select
    collapse-tags
    v-bind="$attrs"
    v-on="listeners"
    v-model="selected"
    style="width: 100%"
  >
    <el-option
      :value="item[valueField]"
      :label="item[labelField]"
      v-for="(item, index) in list"
      :key="index"
      :disabled="item.disabled"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    //separator配合multiple一起使用 可根据分隔符返回join后的数组
    separator: {
      type: String,
    },
    //指定label字段名 默认label
    labelField: {
      type: String,
      default: 'label',
    },
    // 指定value字段名 默认value
    valueField: {
      type: String,
      default: 'value',
    },
    //默认值
    value: [String, Number, Array],
    //字典数组 默认空数组
    dict: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    //需要排除的Value array ["1","2","3"]
    exclude: Array,
    //在字典数组开头插入
    insertBefore: {
      type: Array,
      default() {
        return [];
      },
    },
    //在字典数组最后插入
    insertAfter: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    list: function () {
      var excludeList = [];
      if (this.$props.exclude) {
        excludeList = this.exclude.map((item) => {
          return item.toString();
        });
      }
      let list = this.$props.insertBefore
        .concat(this.$props.dict)
        .concat(this.$props.insertAfter);
      return list.filter((item) => {
        item[this.valueField] = item[this.valueField].toString();
        return !item.hidden && !excludeList.includes(item[this.valueField]);
      });
    },
    listeners: function () {
      let result = {};
      Object.keys(this.$listeners).map((key) => {
        if (key != 'input') {
          result[key] = this.$listeners[key];
        }
      });
      return result;
    },
  },
  data: function () {
    return {
      selected: '',
    };
  },
  watch: {
    value: function (newVal) {
      if (
        this.$attrs.multiple != undefined &&
        this.$props.separator != undefined
      ) {
        newVal = newVal
          ? newVal.split(this.$props.separator).map((item) => item.toString())
          : [];
      }
      if (this.isEqual(newVal, this.selected)) {
        return false;
      } else {
        this.changeVal();
      }
    },
    selected: function (newVal) {
      if (this.$attrs.multiple != undefined) {
        //多选逻辑
        if (this.$props.separator != undefined) {
          //返回字符串
          newVal = newVal.join(this.$props.separator);
        } else {
          //返回数组
          // newVal = JSON.parse(JSON.stringify(newVal));
        }
      } else {
        newVal = newVal != null ? newVal.toString() : '';
      }
      this.$emit('input', newVal);
      let items = this.list.filter((item) => {
        return item[this.$props.valueField] == newVal;
      });
      this.$emit('select', items);
    },
  },
  created() {
    if (this.$props.value) {
      this.changeVal();
    }
  },
  methods: {
    isEqual(val1, val2) {
      let type1 = Object.prototype.toString.call(val1);
      let type2 = Object.prototype.toString.call(val2);
      if (type1 != type2) {
        return false;
      }

      if (type1 === '[object String]') {
        return val1 == val2;
      }
      if (type1 === '[object Array]') {
        if (val1.length != val2.length) {
          return false;
        }
        if (val1.length == 0) {
          return true;
        }
        let r = val1.some((item, index) => {
          return val2[index] != item;
        });
        return !r;
      }
    },

    changeVal() {
      let val = this.$props.value;
      if (this.$attrs.multiple != undefined) {
        //多选逻辑
        if (this.$props.separator != undefined) {
          //value是字符串
          val = val
            ? val.split(this.$props.separator).map((item) => item.toString())
            : [];
        } else {
          //value是数组
          val = val.length ? val.map((item) => item.toString()) : [];
        }
      } else {
        val = val.toString();
      }
      this.selected = val;
    },
  },
};
</script>

<style></style>
