<template>
  <section class="admin-header">
    <div class="admin-header-left">
      <i
        :class="
          $store.state.ui.menuFold ? 'el-icon-s-unfold' : 'el-icon-s-fold'
        "
        class="header-block"
        @click="foldMenu"
      ></i>
    </div>
    <div class="admin-header-right">
      <el-dropdown size="medium" trigger="click">
        <div class="header-item admin-animate-link">
          {{ $store.state.user.adminName }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div @click="logout">退出</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </section>
</template>

<script>
import utils from "@/utils/utils";
import { logout } from "@/api/index";
export default {
  data() {
    return {};
  },
  methods: {
    foldMenu() {
      this.$store.commit("setFold");
    },
    async openEditPwd() {
      let vNode = this.$createElement((await import("./edit-my-pwd")).default, {
        key: Math.random(),
      });
      this.$adminDialog(vNode, {
        title: "修改密码",
        width: "430px",
      });
    },
    async logout() {
      await logout();
      await this.$store.dispatch("user/logout");
      this.$router.replace({
        name: "login",
        query: { redict: encodeURIComponent(this.$route.fullPath) },
      });
    },

    fullScreen() {
      utils.setFullscreen();
    },
  },
};
</script>

<style lang="scss">
.admin-bell-item {
  border-bottom: 1px solid $--border-color-light;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 5px;

  .text {
    small {
      color: $--color-text-placeholder;
    }
  }

  .img {
    width: 40px;
    margin-right: 5px;
    text-align: center;
    flex-shrink: 0;
  }

  &:last-child {
    border-bottom: none;
  }
}
</style>
