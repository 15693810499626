export default {
  namespaced: true,
  state:{name:'jack'},
  getters:{
    getName(state){
      return state.name
    }
  },
  mutations:{
    // 同步函数
    changeName(state,prop){
      state.name = prop
    }
  },
  actions:{
    // 可异步
    changeNameAc(context,props){
      setTimeout(()=>{
        context.commit('changeName',props)
      },1000)
    }
  }
}