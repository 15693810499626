<template>
  <el-menu
    :default-active="$route.path"
    :unique-opened="true"
    router
    :collapse="$store.state.ui.menuFold"
    class="admin-slider-menu"
  >
    <div class="header-brand">
      <i class="el-icon-s-tools"></i>
      <div class="brand-title" v-show="!fuck">信息采集管理后台</div>
    </div>
    <basic-navgator-item
      v-for="route in rootRoutes.children"
      :route="route"
      :key="route.path"
    ></basic-navgator-item>
  </el-menu>
</template>
<script>
import { rootRoutes } from "@/router/routes";
import basicNavgatorItem from "./basic-navgator-item";
export default {
  components: { basicNavgatorItem },
  data() {
    return {
      rootRoutes,
    };
  },
  computed: {
    fuck() {
      return this.$store.state.ui.menuFold;
    },
  },
  watch: {
    fuck(newval) {
      console.log(newval);
    },
  },
};
</script>

<style></style>
