//user API
import ajax from '../ajax.js';
// import qs from "qs";
export const login = (query) => {
  return ajax.post('/admin/login', query);
};
export const logout = (query) => {
  return ajax.post('/admin/logout', query);
};
export const editMyPwd = (query) => {
  return ajax.post('/', query);
};
// 获取管理员列表
export const getRoleList = (query) => {
  return ajax.post('/admin/list', query);
};
// 获取管理员信息
export const adminInfo = (query) => {
  return ajax.post('/admin/info/' + query.id);
};
// 添加管理员信息
export const addAdmin = (query) => {
  return ajax.post('/admin/add', query);
};
// 绑定管理员和地址
export const adminBinding = (query) => {
  return ajax.post('/admin/binding', query);
};
// 获取街道下拉列表
export const getAddrStreetList = (query) => {
  return ajax.post('/admin/addr/street/sel', query);
};
// 获取社区下拉列表
export const getAddrCommunityList = (query) => {
  return ajax.post('/admin/addr/community/sel', query);
};
// 获取地址列表
export const getAddrList = (query) => {
  return ajax.post('/admin/addr/list', query);
};
// 获取地址详情
export const getAddrInfo = (query) => {
  return ajax.post('/admin/addr/info/' + query.id);
};
// 修改位置信息
export const getAddrEdit = (query) => {
  return ajax.post('/admin/addr/edit', query);
};
// 导入数据
export const addrUpload = (query) => {
  console.log(query)
  return ajax.post('/admin/addr/upload/' + query.id, query.params);
};