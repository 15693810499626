<template>
  <section class="admin-container">
    <section class="admin-body">
      <section class="admin-slider" style="width: auto">
        <basic-navgator />
      </section>
      <div class="admin-body-view">
        <basic-header />
        <router-view />
      </div>
    </section>
  </section>
</template>

<script>
import basicNavgator from "./basic-navgator";
import basicHeader from "./basic-header";
export default {
  components: { basicNavgator, basicHeader },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      excludeList: [], // 用于刷新
      view: null,
    };
  },
  watch: {
    $route() {
      this.createView();
    },
  },
  created() {
    this.createView();
  },
  methods: {
    createView() {
      let matchedComponents = this.$router.getMatchedComponents(
        this.$route.fullPath
      );
      let newView = matchedComponents[matchedComponents.length - 1];
      this.$set(this.$data, "view", newView);
    },
    reload() {
      let view = this.view;
      this.excludeList = [this.$route.name];
      this.view = null;
      this.$nextTick(function () {
        this.view = view;
        this.excludeList = [];
      });
    },
  },
};
</script>

<style></style>
