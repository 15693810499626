const dict = {
  //全局字典
  SETTING: {
    OPEN_PERMISSION: true, //是否开启权限
    // API_URL: 'https://gmjd.zsv.com/info-collect', //API具体路径
    API_URL: 'http://101.43.133.59/info-collect',
    AREA_CODE: 131000,
  },
  sex: [
    { label: '未知', value: 0 },
    { label: '男', value: 1 },
    { label: '女', value: 2 }
  ],
  roleType: [
    { label: '理员', value: 1 },
    { label: '街道办事处', value: 2 },
    { label: '社区居委会', value: 3 },
  ]
};
try {
  dict.AREA = window.SYS_AREA_INDEX[dict.SETTING.AREA_CODE].data;
} catch (err) {
  console.error('城市编码配置不正确！');
}
/**
 * 生产环境 使用 public目录下 setting.js声明的全局变量
 */
if (process.env.NODE_ENV === 'production') {
  Object.assign(dict.SETTING, window.ADMIN_SETTING);
}

dict.getValue = function (dict, value, key) {
  if (value == null || value == undefined) {
    return '';
  }
  if (typeof value == 'object') {
    value = value[key];
  }
  var item =
    dict.find((item) => {
      return item.value == value;
    }) || {};
  return item.label || '--';
};
dict.getData = function (dict, key, value) {
  if (value == null || value == undefined) {
    return '';
  }
  if (typeof value == 'object') {
    value = value[key];
  }
  var item =
    dict.find((item) => {
      return item.value == value;
    }) || {};
  return item || {};
};
export default dict;
