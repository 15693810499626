<template>
  <div class="mac-input">
    <el-input
      :disabled="disabled"
      v-model="arr[i]"
      @keyup.native="
        (event) => {
          keyupHanlder(i, event);
        }
      "
      @input="inputHanlder(i)"
      :ref="'mac' + i"
      maxlength="2"
      class="mac-item"
      v-for="(item, i) in new Array(6)"
      :key="i"
      @paste.native="paste"
      :validate-event="false"
    ></el-input>
  </div>
</template>

<script>
export default {
  props: {
    //默认值
    value: String,
    disabled: Boolean,
    disabledEnd: Boolean,
    endNum: String,
  },
  data() {
    return {
      arr: [],
    };
  },
  watch: {
    value: function () {
      this.arr = this.$props.value.split('-');
    },
    arr: function (newVal) {
      this.$emit('input', newVal.slice(0, 6).join('-'));
    },
  },
  created() {
    if (this.$props.value) {
      this.arr = this.$props.value.split('-');
    }
  },
  methods: {
    paste(event) {
      let paste = (event.clipboardData || window.clipboardData).getData('text');
      if (
        paste &&
        /^[A-F\d]{2}-[A-F\d]{2}-[A-F\d]{2}-[A-F\d]{2}-[A-F\d]{2}-[A-F\d]{2}$/.test(
          paste,
        )
      ) {
        setTimeout(() => {
          this.arr = paste.split('-');
        });
        return false;
      }
      if (
        paste &&
        paste.match(/([A-F\d]{2})/g) &&
        paste.match(/([A-F\d]{2})/g).length == 6
      ) {
        //匹配各种相似规则：例如 1122-3344-5566 112233445566 11223-3445566 均可匹配
        setTimeout(() => {
          this.arr = paste.match(/([A-F\d]{2})/g);
        });
        return false;
      }
    },
    inputHanlder(index) {
      this.arr[index] = this.arr[index].replace(/-/g, '').toUpperCase();
    },
    keyupHanlder(index, event) {
      let reg = /^[A-F\d-]$/;
      if (!reg.test(event.key)) {
        return false;
      }
      if (!this.arr[index]) {
        return false;
      }
      let nextInput = this.$refs['mac' + (index + 1)];
      if (event.key == '-' && nextInput) {
        nextInput.select();
        nextInput.focus();
      }
    },
  },
};
</script>

<style lang="scss">
.mac-input {
  display: flex;
  justify-content: space-between;
  .mac-item {
    margin-right: 20px;
    &:after {
      content: '-';
      position: absolute;
      right: -12px;
    }
    &:last-child {
      &:after {
        content: '';
      }
      margin-right: 0;
    }
  }
}
</style>
