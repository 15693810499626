<template>
  <!-- 文档参见 https://www.vue-treeselect.cn/ -->
  <div>
    <treeselect
      v-bind="$attrs"
      v-on="$listeners"
      @select="select"
      @close="blur"
      :limit-text="formatLimit"
      :class="[
  'admin-select-tree',
  inputSize ? 'el-select-tree--' + inputSize : ''
  ]"
    />
  </div>
</template>

<script>
import emitter from 'element-ui/src/mixins/emitter';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  inheritAttrs: false,
  props: {
    size: String,
    validateEvent: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [emitter],
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  computed: {
    _elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize;
    },
    inputSize() {
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
  },
  components: {Treeselect},
  methods: {
    blur(val) {
      if (this.validateEvent) {
        this.$nextTick(() => {
          this.dispatch('ElFormItem', 'el.form.blur', [val]);
        });
      }
    },
    select(val) {
      if (this.validateEvent) {
        this.$nextTick(() => {
          this.dispatch('ElFormItem', 'el.form.change', [val]);
        });
      }
      this.$emit('change', val);
    },
    formatLimit(count) {
      return '已选' + count + '个';
    },
  },
};
</script>

<style lang="scss">
.admin-select-tree {
  width: 200px;
  font-size: 14px;
  min-height: 40px;
  line-height: 40px;
  .vue-treeselect__multi-value-item {
    font-size: inherit;
  }
  &.vue-treeselect--searchable.vue-treeselect--multi.vue-treeselect--has-value
    .vue-treeselect__input-container {
    padding-top: 0;
    vertical-align: bottom;
  }
  .vue-treeselect--has-value .vue-treeselect__multi-value {
    margin-bottom: 3px;
  }
  .vue-treeselect__multi-value-item-container {
    padding-top: 3px;
    vertical-align: bottom;
  }
  .vue-treeselect__control {
    height: inherit;
    line-height: 1;
    min-height: inherit;
  }
  .vue-treeselect__limit-tip {
    vertical-align: bottom;
    padding-left: 5px;
  }
  .vue-treeselect__control-arrow {
    transition: transform 0.2s;
  }
  .vue-treeselect__placeholder,
  .vue-treeselect__single-value {
    line-height: 1;
    bottom: auto;
    top: 50%;
    transform: translate(0, -50%) !important;
  }

  &.el-select-tree--medium {
    font-size: 14px;
    min-height: 36px;
    line-height: 36px;
  }

  &.el-select-tree--small {
    font-size: 13px;
    min-height: 32px;
    line-height: 32px;
  }

  &.el-select-tree--mini {
    font-size: 12px;
    min-height: 28px;
    line-height: 28px;
  }
}
.el-form-item.is-error .vue-treeselect__control {
  border-color: $--color-danger !important;
}
</style>