<template>
  <div class="admin-login-container">
    <div class="banner-text">
      <h2>信息采集管理系统</h2>
      <small>FE management system templates</small>
    </div>
    <el-form
      :model="postData"
      :rules="rules"
      @submit.native.prevent="submit"
      ref="postForm"
      class="admin-login"
    >
      <h1 class="slogan">
        登录
        <small>LOGIN</small>
      </h1>
      <el-form-item
        label="账号"
        prop="adminLoginName"
        style="margin-bottom: 30px"
      >
        <el-input
          autocomplete="off"
          v-model="postData.adminLoginName"
          placeholder="请输入账号……"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="密码"
        prop="adminLoginPwd"
        style="margin-bottom: 30px"
      >
        <el-input
          v-model="postData.adminLoginPwd"
          type="password"
          placeholder="请输入密码……"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          autocomplete="off"
          :loading="loading"
          type="primary"
          native-type="submit"
          class="admin-block-button login-btn"
          >登陆</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login } from "@/api";
import md5 from "@/utils/md5.js";
export default {
  data() {
    return {
      loading: false,
      rules: {
        adminLoginName: [this.$rules.required],
        adminLoginPwd: [this.$rules.required],
      },
      postData: {
        adminLoginName: "",
        adminLoginPwd: "",
      },
    };
  },
  methods: {
    submit() {
      this.$refs.postForm.validate((isValidate) => {
        if (isValidate) {
          this.loading = true;
          let postData = Object.assign({}, this.postData);
          postData.adminLoginPwd = md5(postData.adminLoginPwd, 32);
          login(postData)
            .then(async (res) => {
              if (res.data.code == 200) {
                await this.$store
                  .dispatch("user/login", res.data.data)
                  .catch((err) => {
                    console.error(err);
                  });
                let redict = decodeURIComponent(
                  this.$route.query.redict || "/"
                );
                this.$router.replace(redict);
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.login-btn {
  height: 60px;
  line-height: 60px;
  border-radius: 6px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px !important;
}
.admin-login-container {
  width: 100%;
  height: 100%;
  background: url(/static/bg.jpg) no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.admin-login {
  .slogan {
    text-align: center;
    font-size: 30px;
    text-align: center;
    color: #333333;
    margin-bottom: 50px;
    small {
      display: block;
      margin-top: 5px;
      color: #bbbbbb;
      font-weight: normal;
      font-size: 16px;
    }
  }
  width: 434px;
  height: 474px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 7px 6px rgba(0, 0, 0, 0.37);
  padding: 30px;
  padding-bottom: 10px;
  box-sizing: border-box;
  position: absolute;
  right: 18%;
  top: 50%;
  transform: translate(0, -50%);

  .el-form-item__label {
    font-size: 20px;
    font-weight: bold;
  }
  .el-input__inner {
    border-width: 0 0 1px 0;
    border-radius: 0;
    padding-left: 0;
  }
}
.banner-text {
  height: 218px;
  padding: 53px 0 0 8%;
  background: rgba(29, 76, 149, 0.6);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  box-sizing: border-box;
  h2 {
    font-size: 55px;
    color: #fff;
    line-height: 1;
    margin: 0 0 20px 0;
  }
  small {
    color: rgba(255, 255, 255, 0.6);
    font-family: Arial;
    line-height: 1;
    font-size: 20px;
  }
}
</style>
