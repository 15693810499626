<script>
import {router} from '@/router';
import {hasRoutePermission} from '@/router/index';
export default {
  name: 'BasicNavgatorItem',
  props: {
    route: Object,
  },
  computed: {
    isActive: function () {
      // 在hideChildren的导航中 保持active状态
      if (this.route.children) {
        let isActive = this.$route.matched.some((item) => {
          return item.meta.key == this.route.meta.key;
        });
        return isActive;
      }
      return false;
    },
  },
  created() {},
  render() {
    var route = this.$props.route;

    if (route.meta.hide || !hasRoutePermission(route.meta.key)) {
      return '';
    }
    if (route.meta.hideChildren) {
      return (
        <el-menu-item
          index={route.path}
          i={route.path}
          route={route}
          class={this.isActive ? ' is-active' : ''}>
          <i class={route.meta.icon + ' admin-navigator-icon'} />
          <span slot="title">{route.meta.name}</span>
        </el-menu-item>
      );
    }
    if (route.children) {
      var children = route.children.map((route) => {
        return <basic-navgator-item route={route} />;
      });
      return (
        <el-submenu index={router.match(route).fullPath}>
          <template slot="title">
            <i class={route.meta.icon + ' admin-navigator-icon'} />
            <span slot="title">{route.meta.name}</span>
          </template>
          {children}
        </el-submenu>
      );
    }
    return (
      <el-menu-item index={router.match(route).fullPath}>
        <i class={route.meta.icon + ' admin-navigator-icon'} />
        <span slot="title">{route.meta.name}</span>
      </el-menu-item>
    );
  },
};
</script>
