export default {
  state: {
    menuFold: false, //左侧收缩
  },
  mutations: {
    setFold(state) {
      state.menuFold = !state.menuFold;
    }
  },
  actions: {},
  getters: {}
}