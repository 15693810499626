import moment from 'moment';
import { Message } from 'element-ui';
/**
 * @exports {$utils}
 */
export default {
  /**
   * @param {string} name url中的key
   * @returns {string|null} 返回null或string
   */

  getUrlParams(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i'); //定义正则表达式
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  /**
   * 函数去抖
   * @param {function} func
   * @param {number} delay
   */
  debounce(func, delay) {
    var tId;
    return function () {
      var context = this;
      var arg = arguments;
      if (tId) clearTimeout(tId);
      tId = setTimeout(function () {
        func.apply(context, arg);
      }, delay);
    };
  },
  /**
   * 函数节流
   * @param {function} func
   * @param {number} delay
   */
  throttle(func, delay) {
    var timer = null;
    var startTime = Date.now();
    return function () {
      var curTime = Date.now();
      var remaining = delay - (curTime - startTime);
      var context = this;
      var args = arguments;
      clearTimeout(timer);
      if (remaining <= 0) {
        func.apply(context, args);
        startTime = Date.now();
      } else {
        timer = setTimeout(func, remaining);
      }
    };
  },
  /**
   * 当前是否进入全屏状态
   * @returns {Boolean}
   */
  isFullScreen() {
    return (
      document.isFullScreen ||
      document.mozIsFullScreen ||
      document.webkitIsFullScreen
    );
  },
  /**
   * 全屏开关
   * @param {HTMLElement|undefined} element
   */
  setFullscreen(element) {
    if (!this.isFullScreen()) {
      this.fullScreen(true, element || document.documentElement);
    } else {
      this.fullScreen(false);
    }
  },
  /**
   * 全屏
   * @param {bolean} isOpen -是否全屏
   * @param {HTMLElement} target -要进入全屏的DOM
   */
  fullScreen(isOpen, target) {
    let dom = target;
    let open_list = [
      'requestFullscreen',
      'mozRequestFullScreen',
      'webkitRequestFullScreen',
      'msRequestFullscreen',
    ];
    let cancel_list = [
      'exitFullscreen',
      'mozCancelFullScreen',
      'webkitCancelFullScreen',
    ];
    let fn = void 0;
    if (isOpen) {
      fn = open_list.find((n) => {
        return Boolean(dom[n]);
      });
      fn && dom[fn]();
    } else {
      fn = cancel_list.find((n) => {
        return Boolean(document[n]);
      });
      fn && document[fn]();
    }
  },
  /**
   * 获取区域对象
   * @param {string|number} areaId -区域ID
   * @returns {object|undefined} -返回区域对象
   */
  getArea(areaId) {
    if (!areaId) {
      return;
    }
    return window.SYS_AREA_INDEX[areaId];
  },
  /**
   * 获取区域对象路径
   * @param {string|number} areaId - 区域ID
   * @returns {array} -返回由大到小的地址名称 ['110000','110001']
   */
  getAreaFullId(areaId) {
    let arrPath = [];
    if (!areaId) {
      return arrPath;
    }
    let areaData = this.getArea(areaId);
    if (!areaData) {
      return arrPath;
    }
    while (areaData) {
      arrPath.unshift(areaData.data.code);
      areaData = areaData.parent;
    }
    return arrPath;
  },
  /**
   * 获取区域名称
   * @param {string|number} areaId - 区域ID
   * @returns {string}
   */
  getAreaName(areaId) {
    if (!areaId) {
      return '';
    }
    let areaData = this.getArea(areaId);
    if (!areaData) {
      return '';
    }
    return areaData.data.name || '';
  },
  /**
   * 获取区域名称路径
   * @param {string|number} areaId - 区域ID
   * @returns {array} -返回由大到小的地址名称 ['北京市','东城区']
   */
  getAreaNameFull(areaId) {
    let arrPath = [];
    if (!areaId) {
      return arrPath;
    }
    let areaData = this.getArea(areaId);
    if (!areaData) {
      return arrPath;
    }
    while (areaData) {
      arrPath.unshift(areaData.data.name);
      areaData = areaData.parent;
    }
    return arrPath;
  },
  /**
   * 处理通用的异步返回
   * @param promise 调用/api/* 中函数返回的promise
   * @param vm vue的实例字段
   */

  resolveRequest(promise, vm, callback) {
    promise
      .then((res) => {
        vm.loading = false;
        if (res.data.code == 200) {
          vm.$message.success(res.data.msg);
          vm.$emit('success');
          vm.$emit('end');
          typeof callback === 'function' ? callback() : '';
        } else {
          vm.$message.error(res.data.msg);
        }
      })
      .catch(() => {
        vm.loading = false;
        vm.$message.error('返回数据异常');
      });
  },
  /**
   *
   * @param {string|number} timestamp -10位或13位时间戳
   * @param {string} format -自定义格式 @see http://momentjs.cn/docs/#/parsing/string-format/
   */
  formatDate(timestamp, format) {
    format = format || 'YYYY-MM-DD';
    if (timestamp) {
      return moment(
        timestamp,
        timestamp.toString().length == 10 ? 'X' : 'x',
      ).format(format);
    }
    return '--';
  },
  /**
   *
   * @param {string|number} timestamp
   */
  formatDateFull(timestamp) {
    if (timestamp) {
      return moment(
        timestamp,
        timestamp.toString().length == 10 ? 'X' : 'x',
      ).format('YYYY-MM-DD HH:mm:ss');
    }
    return '--';
  },
  /**
   * 一维数组转tree结构
   * @param data 一维数组对象
   */
  arrToTree(data, idKey = 'id', pidKey = 'pid', topId = 0) {
    // 递归

    function getNode(id) {
      let node = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i][pidKey] == id) {
          data[i].children = getNode(data[i][idKey]);
          node.push(data[i]);
        }
      }
      if (node.length == 0) {
        return;
      } else {
        return node;
      }
    }
    // 根节点
    return getNode(topId);
  },
  /**
   * 文件下载
   * @param {String} fileName
   * @param {String} url
   */
  downloadFile(fileName, url) {
    var elemA = document.createElement('a');
    elemA.href = encodeURI(url);
    elemA.setAttribute('target', '_blank');
    document.body.appendChild(elemA);
    elemA.click();
    elemA.remove();
  },
  /**
   * 文件导入方法
   * @param {Function} api
   * @param {Number} type  1.新入列表导入 2.正式员工列表打入 3.退休人员 4.离职
   * @param {Object} vm vue this
   */
  importFile(api, query, vm) {
    var input = document.createElement('input');
    input.type = 'file';
    input.name = 'type';
    input.accept =
      'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    input.onchange = () => {
      vm.tableLoading = true;
      let params = new FormData();
      params.append('file', input.files[0]);
      api({ id: query.addrId, params: params })
        .then((res) => {
          vm.tableLoading = false;
          if (res.data.code == 200) {
            Message.success(res.data.msg);
            vm.getTable();
          } else {
            Message.error(res.data.msg);
          }
        })
        .catch(() => {
          vm.tableLoading = false;
        });
    };
    input.click();
  },
  /**
   *
   * @param {数字大写} num
   * @returns
   */
  capitalMoney(num) {
    var strOutput = '';
    var strUnit = '仟佰拾亿仟佰拾万仟佰拾元角';
    num += '0';
    var intPos = num.indexOf('.');
    if (intPos >= 0) {
      num = num.substring(0, intPos) + num.substr(intPos + 1, 1);
    }
    strUnit = strUnit.substr(strUnit.length - num.length);
    for (var i = 0; i < num.length; i++) {
      strOutput +=
        '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i, 1), 1) +
        strUnit.substr(i, 1);
    }

    return strOutput
      .replace(/零角$/, '整')
      .replace(/零[仟佰拾]/g, '零')
      .replace(/零{2,}/g, '零')
      .replace(/零([亿|万])/g, '$1')
      .replace(/零+元/, '元')
      .replace(/亿零{0,3}万/, '亿')
      .replace(/^元/, '零元');
  },
  /**
   * 加法函数，避免小数丢失精度
   */
  decimalsAdd() {
    var args = arguments, //获取所有的参数
      d = 0, //定义小数位的初始长度，默认为整数，即小数位为0
      sum = 0; //定义sum来接收所有数据的和
    //循环所有的参数
    for (var key in args) {
      //遍历所有的参数
      //把数字转为字符串
      var str = '' + args[key];
      if (str.indexOf('.') != -1) {
        //判断数字是否为小数
        //获取小数位的长度
        var temp = str.split('.')[1].length;
        //比较此数的小数位与原小数位的长度，取小数位较长的存储到d中
        d = d < temp ? temp : d;
      }
    }
    //计算需要乘的数值
    var m = Math.pow(10, d);
    //遍历所有参数并相加
    for (var cell in args) {
      sum += args[cell] * m;
    }
    //返回结果
    return sum / m;
  },
  /**
   * 小数相乘，解决丢失精度问题
   * @param {Number} value1
   * @param {Number} value2
   */
  accMul(value1, value2) {
    if (value1 == 0 || value2 == 0) {
      return 0;
    }
    let m = 0;
    let v1 = value1.toString();
    let v2 = value2.toString();
    m += v1.split('.')[1] ? v1.split('.')[1].length : 0;
    m += v2.split('.')[1] ? v2.split('.')[1].length : 0;
    let _v1 = Number(v1.replace('.', ''));
    let _v2 = Number(v2.replace('.', ''));
    return (_v1 * _v2) / Math.pow(10, m);
  },
  /**
   * 小数除法，解决丢失精度问题
   * @param {Number} value1 除数
   * @param {*} value2 被除数
   */
  accDiv(value1, value2) {
    if (value1 == 0) {
      return 0;
    }
    let v1 = value1.toString();
    let v2 = value2.toString();
    let m1 = v1.split('.')[1] ? v1.split('.')[1].length : 0;
    let m2 = v2.split('.')[1] ? v2.split('.')[1].length : 0;
    let _v1 = Number(v1.replace('.', ''));
    let _v2 = Number(v2.replace('.', ''));
    return this.accMul(_v1 / _v2, Math.pow(10, m2 - m1));
  },
  /**
   * 获取树结构已选节点：获取子节点全选的父节点的信息
   * @param {*} store === this.$refs.tree.store
   * @returns
   */
  getSimpleCheckedNodes(store) {
    const checkedNodes = [];
    const traverse = function (node) {
      const childNodes = node.root ? node.root.childNodes : node.childNodes;

      childNodes.forEach((child) => {
        if (child.checked) {
          checkedNodes.push(child.data);
        }
        if (child.indeterminate) {
          traverse(child);
        }
      });
    };
    traverse(store);
    return checkedNodes;
  },
  /**
   * 把二进制文件保存到本地
   * file 为后台返回的文件流
   * 请求接口可能需要设置 responseType: "arraybuffer"
   * name: 文件名，需要带后缀
   */
  exportFile(file, name) {
    let url = window.URL.createObjectURL(new Blob([file]));
    let link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link); // 下载完成移除元素
    window.URL.revokeObjectURL(url); // 释放掉blob对象
  },
  getObjectURL(file) {
    let url = null;
    if (window.createObjectURL != undefined) {
      // basic
      url = window.createObjectURL(file);
    } else if (window.webkitURL != undefined) {
      // webkit or chrome
      url = window.webkitURL.createObjectURL(new Blob([file]));
    } else if (window.URL != undefined) {
      // mozilla(firefox)
      url = window.URL.createObjectURL(new Blob([file]));
    }
    return url;
  },
  /**
   * 鼠标横向拖动滚动
   * @param {dom对象} dom
   * @param {vue对象} vm
   */
  handleMouseDragScroll(dom, vm) {
    let that = vm;
    dom.addEventListener('mousedown', mouseStart);
    function mouseStart(e) {
      if (e.button == 0) {
        // 判断是鼠标左键
        that.gapX = e.clientX;
        that.startX = dom.scrollLeft;
        document.addEventListener('mousemove', mouseMove);
        document.addEventListener('mouseup', mouseUp);
      }
      return false; //阻止默认事件或冒泡
    }
    function mouseMove(e) {
      let left = e.clientX - that.gapX;
      dom.scrollLeft = that.startX - left;
      return false; //阻止默认事件或冒泡
    }
    function mouseUp() {
      document.removeEventListener('mousemove', mouseMove);
      document.removeEventListener('mouseup', mouseUp);
    }
  },
  // 深拷贝
  type(obj) {
    let toString = Object.prototype.toString;
    let map = {
      '[object Boolean]': 'boolean',
      '[object Number]': 'number',
      '[object String]': 'string',
      '[object Function]': 'function',
      '[object Array]': 'array',
      '[object Date]': 'date',
      '[object RegExp]': 'regExp',
      '[object Undefined]': 'undefined',
      '[object Null]': 'null',
      '[object Object]': 'object',
    };
    return map[toString.call(obj)];
  },
  // 深拷贝
  cloneDeep(data) {
    let t = this.type(data);
    let o;
    let i;
    let ni;
    if (t === 'array') {
      o = [];
    } else if (t === 'object') {
      o = {};
    } else {
      return data;
    }
    if (t === 'array') {
      for (i = 0, ni = data.length; i < ni; i++) {
        o.push(this.cloneDeep(data[i]));
      }
      return o;
    } else if (t === 'object') {
      for (i in data) {
        o[i] = this.cloneDeep(data[i]);
      }
      return o;
    }
  },
  /**
   * 随机生成uuid
   * @returns 
   */
  guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16)
    })
  },
  /**
   * 删除字符串中空格
   * @param {*} str 
   * @returns 
   */
  delSpaces(str) {
    let str1 = String(str || '')
    str1 = str1.replace(/\s+/g, '')
    return str1;
  },
  /**
   * 判断两个对象是否相等
   * @param {*} val1 
   * @param {*} val2 
   * @returns 
   */
  isEqual(val1, val2) {
    let type1 = Object.prototype.toString.call(val1);
    let type2 = Object.prototype.toString.call(val2);
    if (type1 != type2) {
      return false;
    }

    if (type1 === "[object String]") {
      return val1 == val2;
    }
    if (type1 === "[object Array]") {
      if (val1.length != val2.length) {
        return false;
      }
      if (val1.length == 0) {
        return true;
      }
      let r = val1.some((item, index) => {
        return val2[index] != item;
      });
      return !r;
    }
  },
};
