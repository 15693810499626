import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import {routeMap} from './routes';
import routes from './routes';
import hasPermission from '@/utils/permission';
import dict from '@/utils/dict';
Vue.use(VueRouter);
/**
 * 重写路由的push方法
 * 解决重复push路由时，报错 message: "Navigating to current location (XXX) is not allowed"的问题
 *
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
const routerReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch((error) => error);
};
const positionDict = new Map();
let bodyView = document.querySelector('.admin-body-view');
export const router = new VueRouter({
  routes,
  scrollBehavior(to) {
    if (!bodyView || !positionDict.has(to.meta.key)) {
      return false;
    }
    bodyView.scrollTo(Object.assign(positionDict.get(to.meta.key)));
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next();
    return false;
  }
  if (!store.getters['user/isLogin']) {
    next({
      name: 'login',
    });
    return false;
  }
  if (!hasRoutePermission(to.meta.key)) {
    next({
      name: '403',
    });
    return false;
  }
  if (!bodyView) {
    bodyView = document.querySelector('.admin-body-view');
  }
  positionDict.set(from.meta.key, {
    left: bodyView ? bodyView.scrollLeft : 0,
    top: bodyView ? bodyView.scrollTop : 0,
  });
  next();
});

/**
 * 用于验证当前登录用户是否有某个路径的权限
 * @param {Symbol} path - fullpath
 */

export function hasRoutePermission(key) {
  if (!dict.SETTING.OPEN_PERMISSION || store.getters['user/isSuperAdmin']) {
    return true;
  }
  /* eslint-disable*/
  if (!routeMap.has(key)) {
    return true;
  }

  let pathRole = routeMap.get(key).meta.p;
  if (!pathRole || pathRole.length == 0) {
    return true;
  }

  return hasPermission(pathRole);
}
/**
 * 用于验证当前登录用户是否有某个路径的权限
 * @param {string} path - fullpath
 */
// export function hasPathPermission(path) {
//   let {route} = router.resolve(path);
//   if (!route.matched.length) {
//     return false;
//   }
//   return hasRoutePermission(route.meta.key);
// }
